import './App.css';
import axios from "axios";
import { Form, Button} from 'semantic-ui-react';
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const selectOptions = [
  { value: "SCIO Škola - Praha 6", label: "SCIO Škola - Praha 6" }
];

export default function App() {
 const form = useRef();

 const sendEmail = (e) => {
   //e.preventDefault(); // prevents the page from reloading when you hit “Send”
   
   emailjs.sendForm('service_hd692fh', 'template_2wnqtsp', form.current, 'hoQqE11c7WXYIZBsZ')
     .then((result) => {
  alert('Děkujeme! Jakmile připravíme akci, o kterou máte zájem, ozveme se Vám mezi prvními. Kromě složky Přijatých zpráv zkontrolujte, prosím, také i svou složku Nevyžádaná pošta a Spam.'); 
  reset({name:"", email:"", phone:"", program:"", choice:"", checkBox:""});
  window.scrollTo(0, 0);
  emailjs.sendForm('service_yknz7r4', 'template_ghiiocm', form.current, 'lDf2kqUzxXt-loIXT')
     }, (error) => {
         alert('Je nám to líto! Něco se pokazilo! Zkuste to, prosím, znovu.');
     });
 
 };

const methods = useForm();
    const { register, control, handleSubmit, reset, formState: { errors } } = useForm();

    return (
        <div>
            <form ref={form} onSubmit={handleSubmit(sendEmail)}>
                
                
		    <div className="form-floating mb-3">
                    
                    <input className="form-control" 
                        type="text" placeholder=""
                        {...register("name", { required: true})}
                    />
		    <label >Jméno a příjmení<span className="required">*</span></label>
		    </div>
                {errors.name && <p style={{color:"red"}}>Pole Jméno a příjmení je požadováno</p>}
		        
        	 <div className="form-floating mb-3">                    
                    <input
                        className="form-control" id="email" type="email" placeholder=""
                        {...register("email",{
                                required: true,
                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            })}
                    />
		    <label>E-mail<span className="required">*</span></label>
		    </div>

		{errors.email && <p style={{color:"red"}}>Pole E-mail je požadováno</p>}

		    <div className="form-floating mb-3">                    
                    <input
                        className="form-control" id="phone" type="phone" placeholder=""
                        {...register("phone")}
                    />
		    <label>Telefon</label>
		    </div>

<div className="form mb-3">  
<select className="form-select" style={{fontSize:"1.4em"}}  {...register('choice', { required: true})}>
  <option value = "" disabled selected> Mám zájem o<span className="required">*</span></option>
  <option value="SCIO Škola - Praha 6">SCIO Škola - Praha 6</option>
  <option value="Všechny kurzy">Všechny kurzy</option>
</select>
</div>                
  {errors.choice && <p style={{color:"red"}}>Pole Vybrat program je požadováno</p>}   

		    <div className="form-floating mb-3">                    
                    <textarea className="form-control" id="message" type="text" placeholder="" style={{height: "10rem"}}
                        {...register("program")}
                    />
		    <label>Poznámka</label>
		    </div>

<div className="form-floating mb-3"> 
	<p><input
              type="checkbox"
              name="selectCheckbox"
              id="selectCheckbox"
              {...register('checkBox', { required: true})}
              
            /> <label style={{color:"black"}}>Souhlasím s</label> <label><a data-bs-toggle="modal" href="#portfolioModalGDPR">Zásady ochrany osobních údajů</a><span className="required" style={{color:"black"}}>*</span></label></p>

</div>
  {errors.checkBox && <p style={{color:"red"}}>Pole Souhlasím s Zásady ochrany osobních údajů je požadováno</p>}  
                
                <Button className="btn btn-primary btn-xl justify-center"  type="submit">Odeslat</Button>
                
            </form>
        </div>
    )
}
